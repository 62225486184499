<template>
  <div id="app">
    
    <el-container>
      <el-header>
        <Header/>
      </el-header>
      <el-main>
        <top-carousel/>
        <content-one/>      
      </el-main>
      <el-footer>我的网站 版权所有 2021-至今         
        <el-link target="_blank" href="https://beian.miit.gov.cn/" type="danger">沪ICP备2021034120号-1</el-link>
        </el-footer>
    </el-container>

  </div>
</template>

<script>

import Header from './components/Header.vue'
import TopCarousel from './components/TopCarousel.vue'
import ContentOne from './components/ContentOne.vue'

export default {
  name: 'App',
  components: {
    TopCarousel,
    ContentOne,
    Header
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
  
}

.el-header, .el-footer {
    background-color: #262626;
    color: #ffffff;    
    line-height: 60px;
  }
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }
  
  .el-main {
    background-color: #ffffff;
    color: #333;
    text-align: center;
    
    padding: 0px;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

</style>
