<template>
  <div>

    <el-row >
        <el-col :span="24"><div class="kxTitle1">核心业务</div></el-col>    
    </el-row>
     <el-row >
        <el-col :span="24"><div class="kxTitle2">以提升客户的业绩和可持续发展能力为己任</div></el-col>        
    </el-row>
    <el-row >
        <el-col :span="8" :xs="24">
            <div class="kxTitle2">
                <el-image :src="require('@/assets/kx1.jpg')" fit="fill"></el-image>
                <h4>移动APP定制开发</h4>
            </div>
        </el-col>        
        <el-col :span="8" :xs="24">
            <div class="kxTitle2"><el-image :src="require('@/assets/kx1.jpg')" fit="fill"></el-image>
             <h4>小程序定制开发</h4>
            </div>
           
        </el-col>        
        <el-col :span="8" :xs="24">
            <div class="kxTitle2"><el-image :src="require('@/assets/kx1.jpg')" fit="fill"></el-image>
            <h4>系统定制开发</h4>
            </div>
            
        </el-col>        
    </el-row>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .kxTitle1{
        line-height: 60px;
        font-size: 28px;
        font-weight: 300;
    }
    .kxTitle2{
        line-height: 60px;
        font-size: 20px;
        font-weight: 300;
    }
</style>